import { lazy } from 'react';

const LandingPage = lazy(() => import('pages/landingPage'));
const ThankYouPage = lazy(() => import('pages/thankYouPage'));
const PageNotFound = lazy(() => import('pages/pageNotFound'));

export const routes = [
  {
    path: '',
    element: <LandingPage />
  },
  {
    path: 'thank-you-page',
    element: <ThankYouPage />
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];
